import { HTMLAttributes } from 'react';

import cx from 'classnames';

type RoundedTagProps = HTMLAttributes<HTMLParagraphElement>;

const RoundedTag = ({ className, children, ...props }: RoundedTagProps) => {
  return (
    <p
      className={cx(
        className,
        'text-white font-medium text-sm bg-coral-100 rounded-br-3xl mb-0 pl-2 pr-4 py-1',
      )}
      {...props}
    >
      {children}
    </p>
  );
};

export default RoundedTag;
