import Link from 'next/link';

import { FuelIcon, SeatIcon, TransmissionIcon } from '@components/common/Icons';
import axios from 'axios';
import cx from 'classnames';
import useVehicleProps from 'hooks/useVehicleProps';
import { Vehicle } from 'models/Vehicle';

import Card, { CardProps } from '../Card/Card';
import CarImage from '../CarImage/CarImage';

export interface VehicleCardProps extends CardProps {
  vehicle: Vehicle;
}
const VehicleCard = ({ className, vehicle, ...props }: VehicleCardProps) => {
  const { thumbImage, title, priceTier1 } = useVehicleProps(vehicle);
  const vehicleFuelType = vehicle?.fuel_type
    ? vehicle.fuel_type.split('-')[0]
    : null;
  return (
    <div
      className={cx('col-span-1', className)}
      key={`vehicle-${vehicle.id}`}
      onClick={() => {
        axios.post<string[]>('/api/counter', {
          id: vehicle.id,
          user_view_counter: vehicle.user_view_counter || 0,
        });
      }}
    >
      <Link href={`/subscription-cars/details/vehicles/${vehicle.vin}`}>
        <a>
          <Card
            withShadow
            borderRadius={['md', 'md', 'xl', 'md']}
            className="overflow-hidden w-full transform transition duration-500 hover:scale-[1.02] flex-column justify-between h-full"
            {...props}
          >
            <CarImage
              showReducedBanner={!!vehicle.reduced_banner}
              className="object-cover object-center w-full h-[250px]"
              alt={title}
              src={
                thumbImage?.ad_image?.url ||
                thumbImage?.image_url ||
                '/images/Car.svg'
              }
            />
            <div className="grid grid-cols-3 row-span-1 h-full">
              <div className="col-span-2 row-span-1 p-3">
                <div className="min-h-[50px]">
                  <h4>
                    {vehicle.manufacture_year} {vehicle.make}
                  </h4>
                  <p className="pt-1 min-h-[50px]">
                    {vehicle.model} {vehicle.series}
                  </p>
                </div>
                <div className="flex text-base pt-2">
                  <div className="flex items-center mr-3">
                    <TransmissionIcon className="w-4 h-4 mr-1 text-coral-100" />
                    <span>
                      {vehicle.transmission_type?.replace('Automatic', 'Auto')}
                    </span>
                  </div>
                  <div className="flex items-center mr-3">
                    <FuelIcon className="w-4 h-4 mr-1 text-coral-100" />
                    <span>{vehicleFuelType}</span>
                  </div>
                  <div className="flex items-center">
                    <SeatIcon className="w-4 h-4 mr-1 text-coral-100" />
                    <span>{vehicle.seat_count}</span>
                  </div>
                </div>
              </div>
              <div className="col-span-1 p-3 bg-blue-5 row-span-1">
                <div className="mr-3 border-b-4 border-coral-100">
                  <small className="block font-bold text-blue-100">FROM</small>
                  <h3 className="flex items-start -mb-2 font-bold text-dark-blue-100">
                    <span className="mt-1 mr-1 text-sm">$</span>
                    <span>{priceTier1 && Math.round(priceTier1)}</span>
                  </h3>
                  <span className="text-sm text-blue-100">per week</span>
                </div>
                {!!vehicle.reduced_banner && (
                  <div>
                    <p className="text-sm font-medium text-coral-100">
                      <span>Was </span>
                      <span>
                        $
                        {vehicle.previous_car_subscription_total_price &&
                          Math.round(
                            vehicle.previous_car_subscription_total_price,
                          )}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </a>
      </Link>
    </div>
  );
};

export default VehicleCard;
