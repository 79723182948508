import RoundedTag from '../RoundedTag/RoundedTag';

type Props = {
  className: string;
  src: string;
  alt: string;
  showReducedBanner: boolean;
};

const CarImage = ({
  src,
  alt = 'Image',
  className,
  showReducedBanner,
}: Props) => {
  return (
    <div>
      <img className={className} src={src} alt={alt} />
      {showReducedBanner && (
        <RoundedTag className="absolute top-0 left-0">PRICE REDUCED</RoundedTag>
      )}
    </div>
  );
};

export default CarImage;
