import { useMemo } from 'react';

import { Vehicle } from 'models/Vehicle';

const useVehicleProps = (vehicle: Vehicle) => {
  const thumbImage = useMemo(
    () => vehicle.vehicle_images?.[0],
    [vehicle.vehicle_images],
  );

  const title = useMemo(
    () =>
      [
        vehicle.manufacture_year,
        vehicle.make,
        vehicle.model,
        vehicle.series,
      ].join(' '),
    [vehicle.make, vehicle.manufacture_year, vehicle.model, vehicle.series],
  );

  const priceTier1 = useMemo(() => {
    const priceTier1Value =
      (Number(vehicle.loopit_car_subscription_price) || 0) +
      (Number(vehicle.loopit_subscription_plan_price_tier1) || 0);
    return priceTier1Value > 0 ? priceTier1Value : null;
  }, [
    vehicle.loopit_car_subscription_price,
    vehicle.loopit_subscription_plan_price_tier1,
  ]);

  const priceTier2 = useMemo(() => {
    const priceTier2Value =
      (Number(vehicle.loopit_car_subscription_price) || 0) +
      (Number(vehicle.loopit_subscription_plan_price_tier2) || 0);
    return priceTier2Value > 0 ? priceTier2Value : null;
  }, [
    vehicle.loopit_car_subscription_price,
    vehicle.loopit_subscription_plan_price_tier2,
  ]);

  const priceTier3 = useMemo(() => {
    const priceTier3Value =
      (Number(vehicle.loopit_car_subscription_price) || 0) +
      (Number(vehicle.loopit_subscription_plan_price_tier3) || 0);
    return priceTier3Value > 0 ? priceTier3Value : null;
  }, [
    vehicle.loopit_car_subscription_price,
    vehicle.loopit_subscription_plan_price_tier3,
  ]);

  return {
    thumbImage,
    title,
    priceTier1,
    priceTier2,
    priceTier3,
  };
};

export default useVehicleProps;
