import { FC, HTMLAttributes, useCallback } from 'react';

import cx from 'classnames';
import chunk from 'lodash/chunk';
import { PromotionalBanner } from 'models/stack9/PromotionalBanner';
import { Vehicle } from 'models/Vehicle';

import VehicleCard from '../VehicleCard/VehicleCard';

let counterDesktop = 0;
let counterTablet = 0;

const VehicleCardGridBannerLink: FC<{
  promotionalBanner: PromotionalBanner;
  className: string;
  key: string;
}> = ({ promotionalBanner, className, children, key }) => {
  return (
    <a
      target="_blank"
      className={cx('p-4', className)}
      href={promotionalBanner.link}
      key={key}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export interface VehicleCardGridProps extends HTMLAttributes<HTMLDivElement> {
  promotionalBanners?: PromotionalBanner[];
  vehicles: Vehicle[];
}

const VehicleCardGrid = ({
  className,
  promotionalBanners,
  vehicles,
  ...props
}: VehicleCardGridProps) => {
  const getPromotionalBanner = useCallback(() => {
    if (!promotionalBanners) {
      return [undefined, undefined];
    }
    return [
      promotionalBanners[counterDesktop],
      promotionalBanners[counterTablet],
    ];
  }, [promotionalBanners]);

  const totalBanners = promotionalBanners?.length ?? 0;

  return (
    <div
      className={cx(
        'grid gap-5 grid-cols-1 p-4 -m-4 md:grid-cols-2 lg:grid-cols-2',
        className,
      )}
      {...props}
    >
      {!promotionalBanners?.length
        ? vehicles?.map(vehicle => (
            <VehicleCard vehicle={vehicle} key={vehicle.id} />
          ))
        : chunk(vehicles, 3).map((chunkVehicles, index) => {
            const vehicleNumber = index + 1;
            const [promotionalBannerDesktop, promotionalBannerTablet] =
              getPromotionalBanner();

            if (vehicleNumber % 3 === 0) {
              counterDesktop =
                counterDesktop + 1 === totalBanners ? 0 : counterDesktop + 1;
            }

            if (vehicleNumber % 2 === 0) {
              counterTablet =
                counterTablet + 1 === totalBanners ? 0 : counterTablet + 1;
            }
            return [
              ...chunkVehicles.map(chunkVehicle => (
                <VehicleCard vehicle={chunkVehicle} key={chunkVehicle.id} />
              )),
              ...(vehicleNumber % 3 === 0 &&
              promotionalBannerDesktop?.desktop_image.url
                ? [
                    <VehicleCardGridBannerLink
                      className="hidden xl:block xl:col-span-3"
                      promotionalBanner={promotionalBanners[counterDesktop]}
                      key={`promotional-banner-desktop-${promotionalBannerDesktop.id}`}
                    >
                      <img
                        className="w-full block"
                        alt={promotionalBannerDesktop.meta_description}
                        src={promotionalBannerDesktop.desktop_image.url}
                      />
                    </VehicleCardGridBannerLink>,
                  ]
                : []),
              ...(vehicleNumber % 2 === 0 &&
              promotionalBannerTablet?.tablet_image.url
                ? [
                    <VehicleCardGridBannerLink
                      className="hidden sm:block sm:col-span-2 lg:col-span-2 xl:hidden"
                      promotionalBanner={promotionalBannerTablet}
                      key={`promotional-banner-tablet-${promotionalBannerTablet.id}`}
                    >
                      <img
                        className="w-full block"
                        alt={promotionalBannerTablet.meta_description}
                        src={promotionalBannerTablet.tablet_image.url}
                      />
                    </VehicleCardGridBannerLink>,
                  ]
                : []),
              ...(vehicleNumber % 2 === 0 &&
              promotionalBannerTablet?.mobile_image.url
                ? [
                    <VehicleCardGridBannerLink
                      className="col-span-1 md:hidden"
                      promotionalBanner={promotionalBannerTablet}
                      key={`promotional-banner-mobile-${promotionalBannerTablet.id}`}
                    >
                      <img
                        className="w-full block"
                        alt={promotionalBannerTablet.meta_description}
                        src={promotionalBannerTablet.mobile_image.url}
                      />
                    </VehicleCardGridBannerLink>,
                  ]
                : []),
            ];
          })}
    </div>
  );
};

export default VehicleCardGrid;
